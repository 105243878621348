/*
    TypeGeneric
*/

class TypeGeneric {
  constructor(globalId, display) {
    this.globalId = globalId;
    this.display = display;
    this.display_lines = String(this.display).split('^');

    if (this.display === '' || this.display === 'undefined') {
      this.display_lines = [];
    }
  }

  printAsJson(silent=false) {
    var stateAsJson = JSON.stringify(this, null, 4);
    if (!silent)
      console.log(stateAsJson);
    return stateAsJson;
  }
}

/*
    TypeHeader
*/

class TypeHeader extends TypeGeneric {
  constructor(globalId, display, isMain) {
    super(globalId, display);
    this.isMain = isMain;
  }
}

/*
    TypeFixtureInfo
*/

class TypeFixtureInfo extends TypeGeneric {
  constructor(globalId, display) {
    super(globalId, display);

  }
}

/*
    TypeOdds
*/

class TypeOdds extends TypeGeneric {
  constructor(globalId, display, fixtureId, feedSourceId, marketTypeId, field, age, timeStamp) {
    super(globalId, display);
    this.fixtureId = Number(fixtureId);
    this.feedSourceId = Number(feedSourceId);
    this.marketTypeId = Number(marketTypeId);
    this.field = field;
    if (age === undefined)
      age = 999;
    this.age = Number(age);

    if (timeStamp === undefined || timeStamp === 'None') {
      this.timeStamp = null;
      this.timeStampISO = null;
    } else {
      this.timeStamp = new Date(timeStamp);
      this.timeStampISO = this.timeStamp.toISOString();
    }
  }
}

/*
  CellTypeDisctionary
*/

class CellTypeDisctionary {
  constructor(name) {
    this.name = name;
    this.dictionary = {};
  }

  addOrReplaceByGlobalId(globalId, content) {
    var success = true;
    this.dictionary[globalId] = content;
    return success;
  }

  getByGlobalId(globalId) {
    var found = null;
    found = this.dictionary[globalId];
    return found;
  }

  getSizeOfDictionary() {
    return Object.keys(this.dictionary).length;
  }

  printLengthOfDictionary() {
    console.log('total items in dictionary ' + this.name + ' is ' + this.getSizeOfDictionary());

  }

  printAsJson(silent=false) {
    var stateAsJson = JSON.stringify(this.dictionary, null, 4);
    if (!silent)
      console.log(stateAsJson);
    return stateAsJson;
  }
}

/*
    CanvasState
*/


/*
    CanvasConstats
*/

class CanvasConstats {
  constructor(name) {
    this.allFixtureTypeIds = '1,2,4,5,6';
    this.gamesOnlyFixtureTypeIds = '1,2';
    this.playerPropsOnlyFixtureTypeIds = '3';
    this.nonGameAndPlayerPropsOnlyFixtureTypeIds = '4,5,6';
    this.preGameBettingTypeIds = '1';
    this.inGameBettingTypeIds = '2,3';
  }

  printAsJson(silent=false) {
    var constantsAsJson = JSON.stringify(this.dictionary, null, 4);
    if (!silent)
      console.log(constantsAsJson);
    return constantsAsJson;
  }
}
class CanvasState {

  constructor() {
    this.canvasConstants = new CanvasConstats();
    this.urlParams = new URLSearchParams(window.location.search);
    this.canvasType = this.urlParams.get('canvasType');
    this.sportId = this.urlParams.get('sportId');
    this.leagueId = this.urlParams.get('leagueId');
    this.parentFixtureId = this.urlParams.get('parentFixtureId');
    this.parentParticipantId = this.urlParams.get('parentParticipantId');
    this.fixtureId = this.urlParams.get('fixtureId');
    this.fixtureTypeId = this.urlParams.get('fixtureTypeId');
    this.bettingTypeId = this.urlParams.get('bettingTypeId');
    this.feedSourceId = this.urlParams.get('feedSourceId');
    this.segmentId = this.urlParams.get('segmentId');
    this.marketTypeId = this.urlParams.get('marketTypeId');
    this.altId = this.urlParams.get('altId');
    this.startTime = this.urlParams.get('startTime');
    this.endTime = this.urlParams.get('endTime');
    this.customScheduleRange = this.urlParams.get('customScheduleRange');
    this.timeZone = this.urlParams.get('timeZone');
    this.scheduleRange = this.urlParams.get('scheduleRange');
    this.fromCache = this.urlParams.get('fromCache');
    this.currentMarketTypeView = this.urlParams.get('currentMarketTypeView');
    this.currentTabName = this.urlParams.get('currentTabName');
    this.currentSegmentName = this.urlParams.get('currentSegmentName');
    this.currentMarketName = this.urlParams.get('currentMarketName');
    this.currentInMyViews = this.urlParams.get('currentInMyViews');
    this.lockToTodayOnly = this.urlParams.get('lockToTodayOnly');
    this.lockToGamesOnly = this.urlParams.get('lockToGamesOnly');
    this.sortByTime = this.urlParams.get('sortByTime');
    this.inGameMode = this.urlParams.get('inGameMode');
    this.autoRefresh = this.urlParams.get('autoRefresh');
    this.showKiblIds = this.urlParams.get('showKiblIds');
    this.showAlternates = this.urlParams.get('showAlternates');
    this.theme = this.urlParams.get('theme');
    this.refreshInProgress = true;

    this.calculateScopeTimes();
    this.setDefaultValues();
    this.printCurrentStateAsJson();
  };

  calculateScopeTimes() {
    this.userLocalTime = new Date();
    this.userLocalTimeISO = this.userLocalTime.toISOString();
    this.defaultStartTime = new Date(this.userLocalTime.getFullYear(), this.userLocalTime.getMonth(), this.userLocalTime.getDate(), 0, 0, 0);
    this.defaultStartTimeISO = this.defaultStartTime.toISOString();
    this.defaultEndTime = new Date(structuredClone(this.defaultStartTime).setDate(this.defaultStartTime.getDate() + 1));
    this.defaultEndTimeISO = this.defaultEndTime.toISOString();
    this.upcomingEndTime = new Date(structuredClone(this.defaultStartTime).setDate(this.defaultStartTime.getDate() + 180));
    this.upcomingEndTimeISO = this.upcomingEndTime.toISOString();
    this.yesterdayStartTime = new Date(structuredClone(this.defaultStartTime).setDate(this.defaultStartTime.getDate() - 1));
    this.yesterdayStartTimeISO = this.yesterdayStartTime.toISOString();
    this.tomorrowStartTime = new Date(structuredClone(this.defaultStartTime).setDate(this.defaultStartTime.getDate() + 1));
    this.tomorrowStartTimeISO = this.tomorrowStartTime.toISOString();
    this.tomorrowEndTime = new Date(structuredClone(this.defaultStartTime).setDate(this.defaultStartTime.getDate() + 2));
    this.tomorrowEndTimeISO = this.tomorrowEndTime.toISOString();

    if (!this.customScheduleRange) {
      if (this.scheduleRange === 'tomorrow') {
        this.startTime = this.tomorrowStartTimeISO;
        this.endTime = this.tomorrowEndTimeISO;
      } else if (this.scheduleRange === 'upcoming') {
        this.startTime = this.defaultStartTimeISO;
        this.endTime = this.upcomingEndTimeISO;
      } else if (this.scheduleRange === 'yesterday') {
        this.startTime = this.yesterdayStartTimeISO;
        this.endTime = this.defaultEndTimeISO;
      } else {
        this.startTime = this.defaultStartTimeISO;
        this.endTime = this.defaultEndTimeISO;
      }        
    }
  }

  setDefaultValues() {
    if (this.theme === null || this.theme === '')
      this.theme = ''
    else
      this.theme = this.theme;
    if (this.canvasType === null)
      this.canvasType = 'games';
    if (this.showKiblIds === null)
      this.showKiblIds = false;
    if (this.showAlternates === null)
      this.showAlternates = false;
    if (this.currentMarketName === null)
      this.currentMarketName = '';
    if (this.currentSegmentName === null)
      this.currentSegmentName = 'FG';
    if (this.currentTabName === null)
      this.currentTabName = 'TODAY';
    if (this.currentInMyViews === null)
      this.currentInMyViews = false;
    if (this.lockToTodayOnly === null)
      this.lockToTodayOnly = false;
    if (this.lockToGamesOnly === null)
      this.lockToGamesOnly = false;
    if (this.inGameMode === null)
      this.inGameMode = false;
    if (this.autoRefresh === null)
      this.autoRefresh = false;
    if (this.sortByTime === null)
      this.sortByTime = false;
    if (this.currentMarketTypeView === null)
      this.currentMarketTypeView = 'm0';
    if (this.scheduleRange === null)
      this.scheduleRange = 'today';
    if (this.leagueId === null) 
      this.leagueId = '';

    if (this.fixtureTypeId === null) {
      if (this.scheduleRange === 'today')
        this.fixtureTypeId = this.canvasConstants.allFixtureTypeIds;
      else {
        if (this.lockToGamesOnly)
          this.fixtureTypeId = this.canvasConstants.gamesOnlyFixtureTypeIds;
        else
          this.fixtureTypeId = this.canvasConstants.allFixtureTypeIds;
      }
    }


    if (this.bettingTypeId === null) {
      if (this.inGameMode)
        this.bettingTypeId = this.canvasConstants.inGameBettingTypeIds
      else
        this.bettingTypeId = this.canvasConstants.preGameBettingTypeIds;
    }      
      //this.bettingTypeId = '1';
    if (this.timeZone === null)
      this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.marketTypeId === null)
      this.marketTypeId = '0';
    if (this.segmentId === null)
      this.segmentId = '1';
    if (this.altId === null)
      this.altId = '0';
    if (this.startTime === null) {
      this.startTime = this.defaultStartTimeISO;
      this.customScheduleRange = false;
    }
    else {
      this.customScheduleRange = true;
    }
    if (this.endTime === null)
      if (this.scheduleRange !== 'upcoming')
        this.endTime = this.defaultEndTimeISO;
      else
        this.endTime = this.upcomingEndTimeISO;
  }

  updateBrowserTabTitle() {
    var additionalString = (this.lockToTodayOnly ? ' * ' : '');
    document.title = `${this.currentTabName} ${this.currentSegmentName}  ${this.currentMarketName} ${additionalString}`;
  
  }

  updateState(key, value) {
    if (value === null || value === true || value === false)
      eval('this.' + key + '=' + value + '');
    else
      eval('this.' + key + '=\'' + value + '\'');
  }

  printCurrentStateAsJson(silent=false) {
    var stateAsJson = JSON.stringify(this, null, 4);
    if (!silent)
      console.log(stateAsJson);
    return stateAsJson;
  }

  printSharableLink() {
    var variableNotToInclude = ['urlParams', 'userLocalTime', 'userLocalTimeISO', 'defaultStartTime', 'defaultStartTimeISO', 'defaultEndTime', 'defaultEndTimeISO', 'upcomingEndTime', 'upcomingEndTimeISO', 'theme'];
    var domainName = window.location.origin;
    var variables = '';

    for (var name in this) {
      var value = eval('this.' + name)
      if (!variableNotToInclude.includes(name) && value !== null)
        variables += name + "=" + value + "&";
    }
    var link = `${domainName}/?${variables}`

    return link;
  }

  saveCanvasStateToLocalStorage() {
    localStorage.setItem('canvas_state', this.printCurrentStateAsJson(true));
  }

  toggleRefreshViewState() {
    // console.log("refreshing in progress? " + this.refreshInProgress);
    if (this.refreshInProgress)
      this.refreshInProgress = false;
    else
      this.refreshInProgress = true;   
  }
}

/*
    KiblCognitoService
*/

class KiblCognitoService {
  constructor() {
    this.cognito_state = {
      username: '',
      password: '',
      access_token: '',
      refresh_token: '',
      expires_in: 0,
      force_generate: true
    };
  }

  async getToken() {
    var token = '';
    var generate_token_required = false;
    var generate_reason = 'still valid';
  
    if (process.env.REACT_APP_KIBL_ODDS_SERVER_COGNITO_REQUIRED === 'true') {
      var cognito_state_from_local = JSON.parse(localStorage.getItem('cognito_state'));

      if (cognito_state_from_local === null)
        this.saveCognitoStateToLocalStorage();
      else
        this.cognito_state = cognito_state_from_local;

      if (process.env.REACT_APP_KIBL_ODDS_SERVER_COGNITO_TOKEN_FORCE_GENERATE === 'true' && this.cognito_state.force_generate === true) {
        generate_token_required = true;
        generate_reason = 'force generating on every pull';
      }
      else if (this.cognito_state.access_token === null || this.cognito_state.access_token === '') {
        generate_token_required = true;
        generate_reason = 'token missing in local';
      }
      else {
        if (new Date().getTime() > this.cognito_state.expires_in) {
          generate_token_required = true;
          generate_reason = 'ttl time expired';
        }
  
        token = this.cognito_state.access_token;
      }
  
      console.log(`generate token required? ${generate_token_required} because ${generate_reason}`)
  
      if (generate_token_required)
        if ((this.cognito_state.username === null || this.cognito_state.username === '') && (this.cognito_state.password === null || this.cognito_state.password === '')) {
          console.log("using public access");
          token = await this.generateKiblToken(process.env.REACT_APP_KIBL_ODDS_SERVER_COGNITO_PUBLIC_USER, process.env.REACT_APP_KIBL_ODDS_SERVER_COGNITO_PUBLIC_PASSWORD);
        }
        else {
          console.log("using personal access");
          token = await this.generateKiblToken(this.cognito_state.username, this.cognito_state.password);
        }
    }
  
    return token;
  }
  
  async generateKiblToken(username, password) {
    if (process.env.REACT_APP_KIBL_ODDS_SERVER_COGNITO_REQUIRED === 'false')
      return 

    const newTokenRequest = {
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password
      },
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: process.env.REACT_APP_KIBL_ODDS_SERVER_COGNITO_USER_APP_CLIENT_ID
    };
  
    /*
    const refreshTokenRequest = {
      AuthParameters: {
        REFRESH_TOKEN: 'refreshtokenhere'
      },
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: process.env.REACT_APP_KIBL_ODDS_SERVER_COGNITO_USER_APP_CLIENT_ID
    };
    */
  
    const requestOptions = {
      method: 'POST',
      headers: new Headers({'accept': 'application/json', 'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth', 'Content-Type': 'application/x-amz-json-1.1'}),
      body: JSON.stringify(newTokenRequest)
    };
  
    const response = await fetch(process.env.REACT_APP_KIBL_ODDS_SERVER_COGNITO_AUTH_URL, requestOptions);
    const data = await response.json();
    const refresh_token = data['AuthenticationResult']['RefreshToken'];
    const access_token = data['AuthenticationResult']['AccessToken'];
    const access_token_expires_in_seconds = (new Date().getTime() + ((parseInt(data['AuthenticationResult']['ExpiresIn']) - 3)*1000)); // we don't go all the way  :)

    console.log("token generated")

    this.cognito_state.access_token = access_token;
    this.cognito_state.expires_in = access_token_expires_in_seconds;
    this.cognito_state.refresh_token = refresh_token;

    this.saveCognitoStateToLocalStorage();
  
    return access_token;
  }
  
  saveCognitoStateToLocalStorage() {
    localStorage.setItem('cognito_state', JSON.stringify(this.cognito_state));
  }
}

/*
    KiblSocketService
*/

class KiblSocketService {
  constructor() {
    this.socket_state = {
      host: 'rabbitmq.kibl.io',
      port: '15675',
      channel: '',
      connected: true,
      messagesReceived: 0,
      lastMessageTimestamp: ''
    };

  }

  async connect() {
  }
}

export { CanvasState, KiblCognitoService, KiblSocketService, CellTypeDisctionary, TypeGeneric, TypeHeader, TypeFixtureInfo, TypeOdds };